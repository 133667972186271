import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactContent from "../components/contact/contactContent"

const Contact = () => {
  return (
    <Layout pageInfo={{ pageName: "connect" }}>
      <SEO title="contact us" keywords={[`e-commerce`, `shopping`, `feedback`]} />
      {/* 大图 */}
      <div 
        className="connect"
        data-sal="zoom-in"
        data-sal-delay="200"
      >
        <div className="title">Contact us</div>
      </div>
      <ContactContent />
    </Layout>
  )
}

export default Contact

